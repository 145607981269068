import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import App from './App';
import Login from './views/Login';
import Inicio from './views/Inicio';
import Usuario from './views/Usuario';
import Rol from './views/Rol';
import DashBoard from './views/DashBoard';
import NotFound from './views/NotFound';
import Producto from './views/Producto';
import Categoria from './views/Categoria';
import Venta from './views/Venta';
import HistorialVenta from './views/HistorialVenta';
import ReporteVenta from './views/ReporteVenta';

import UserProvider from "./context/UserProvider"
import VerificarUsuario from './components/VerificarUsuario';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('wrapper');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter basename={baseUrl}>
    <UserProvider>
            <Routes>

                {/*ACONTINUACION ESTABLECEMOS LAS RUTAS DE NUESTRO SISTEMA*/}

                {/*ruta individual sin usar una como base*/}
                <Route index path='/Login' element={<Login />} />

                {/*Permite anidar rutas en base a una*/}
                <Route path='/' element={<App />}>

                    <Route index element={<Inicio />} />
                    <Route path='dashboard' element={<VerificarUsuario> <DashBoard /> </VerificarUsuario>} />
                    <Route path='usuario' element={<VerificarUsuario> <Usuario /> </VerificarUsuario>} />
                    <Route path='rol' element={<VerificarUsuario> <Rol /> </VerificarUsuario>} />
                    <Route path='producto' element={<VerificarUsuario> <Producto /> </VerificarUsuario>} />
                    <Route path='categoria' element={<VerificarUsuario> <Categoria /> </VerificarUsuario>} />
                    <Route path='venta' element={<VerificarUsuario> <Venta /> </VerificarUsuario>} />
                    <Route path='historialventa' element={<VerificarUsuario> <HistorialVenta /> </VerificarUsuario>} />
                    <Route path='reporteventa' element={<VerificarUsuario > <ReporteVenta /> </VerificarUsuario>} />

                </Route>
                <Route path='*' element={<NotFound />} />
                
            </Routes>

        </UserProvider>
  </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
