import { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { Card, CardBody, CardHeader, Button, Modal, ModalHeader, ModalBody, Label, Input, FormGroup, ModalFooter, Row, Col } from "reactstrap"
import Swal from 'sweetalert2'

const modeloRol = {
    idRol :0,
    descripcion: "",
    esActivo : true,
    fechaRegistro :""
}

const Rol = () => {
    const [pendiente, setPendiente] = useState(true);
    const [roles, setRoles] = useState([]);
    const [rol, setRol] = useState(modeloRol);
    const [verModal, setVerModal] = useState(false);
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const handleChange = (e) => {
        let value;

        if (e.target.name == "idRol") {
            value = e.target.value
        } else if (e.target.name == "esActivo") {
            value = (e.target.value == "true" ? true : false)
        } else {
            value = e.target.value;
        }

        setRol({
            ...rol,
            [e.target.name]: value
        })
    };

    const obtenerRoles = async () => {
        let response = await fetch(`${backendUrl}/api/rol/Lista`);
        if (response.ok) {
            let data = await response.json()
            setRoles(data)
            setPendiente(false)
        }
    };

    useEffect(() => {
        obtenerRoles();
    }, []);

    const columns = [
        {
            name: 'Descripción',
            selector: row => row.descripcion,
            sortable: true,
        },
        {
            name: 'Estado',
            selector: row => row.esActivo,
            sortable: true,
            cell: row => {
                let clase;
                clase = row.esActivo ? "badge badge-info p-2" : "badge badge-danger p-2"
                return (
                    <span className={clase}>{row.esActivo ? "Activo" : "No Activo"}</span>
                )
            }
        },
        {
            name: 'Fecha de registro',
            selector: row => row.fechaRegistro,
            sortable: true,
        },
        {
            name: '',
            cell: row => (
                <>
                    <Button color="primary" size="sm" className="mr-2"
                        onClick={() => abrirEditarModal(row)}
                    >
                        <i className="fas fa-pen-alt"></i>
                    </Button>

                    <Button color="danger" size="sm"
                        onClick={() => eliminarRol(row.idRol)}
                    >
                        <i className="fas fa-trash-alt"></i>
                    </Button>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                fontSize: '13px',
                fontWeight: 800,
            },
        },
        headRow: {
            style: {
                backgroundColor: "#eee",
            }
        }
    };

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const abrirEditarModal = (data) => {
        setRol(data);
        setVerModal(!verModal);
    };

    const cerrarModal = () => {
        setRol(modeloRol)
        setVerModal(!verModal);
    };

    const guardarCambios = async () => {
        delete rol.idRolNavigation;

        if (rol.idRol === 0) {
            rol.fechaRegistro = new Date().toISOString();
        }

        let response;
        if (rol.idRol == 0) {
            response = await fetch(`${backendUrl}/api/rol/Guardar`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(rol)
            });
        } else {
            response = await fetch(`${backendUrl}/api/rol/Editar`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify(rol)
            });
        }

        if (response.ok) {
            await obtenerRoles();
            setRol(modeloRol)
            setVerModal(!verModal);
        } else {
            const errorMessage = await response.text();
            console.error("Error al guardar:", errorMessage);
            alert("error al guardar")
        }
    };

    const eliminarRol = async (id) => {
        Swal.fire({
            title: 'Esta seguro?',
            text: "Desea eliminar el rol",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, continuar',
            cancelButtonText: 'No, volver'
        }).then((result) => {
            if (result.isConfirmed) {
                const response = fetch(`${backendUrl}/api/rol/Eliminar/` + id, { method: "DELETE" })
                    .then(response => {
                        if (response.ok) {
                            obtenerRoles();
                            Swal.fire(
                                'Eliminado!',
                                'El rol fue eliminado.',
                                'success'
                            )
                        }
                    })
            }
        });
    };

    return (
        <>
            <Card>
                <CardHeader style={{ backgroundColor: '#4e73df', color: "white" }}>
                    Lista de Roles
                </CardHeader>
                <CardBody>
                    <Button color="success" size="sm" onClick={() => setVerModal(!verModal)}>Nuevo Rol</Button>
                    <hr></hr>
                    <DataTable
                        columns={columns}
                        data={roles}
                        progressPending={pendiente}
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        customStyles={customStyles}
                    />
                </CardBody>
            </Card>
            <Modal isOpen={verModal}>
                <ModalHeader>
                    Detalle Rol
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <Label>Descripción</Label>
                                <Input bsSize="sm" name="descripcion" onChange={handleChange} value={rol.descripcion} />
                            </FormGroup>
                        </Col>
                        <Col sm={6} >
                            <FormGroup>
                                <Label>Estado</Label>
                                <Input bsSize="sm" type={"select"} name="esActivo" onChange={handleChange} value={rol.esActivo} >
                                    <option value={true}>Activo</option>
                                    <option value={false}>No Activo</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button size="sm" color="primary" onClick={guardarCambios}>Guardar</Button>
                    <Button size="sm" color="danger" onClick={cerrarModal}>Cerrar</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default Rol;